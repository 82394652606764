$primaryColor: #333333;
$primaryColorFirstShade: #444444;
$primaryColorSecondShade: #707070;
$primaryColorThirdShade: #E4E4E4;
$secondaryColor: #FECC0B;


.repit-field {
  display: flex;
  gap: 0.5rem;
}

.repit-field div:first-child {
  font-weight: bold;
}

.select-filter {
  border: none;
  padding: 1rem;
  width: 100%;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #E4E4E4;
  color: #333333;
}

h1, h2, h3, h4, h5, h6 {
  color: #333333;
  font-weight: 200;
}

h1 {
  font-size: 1.2rem;
  padding: 0.8rem 0;
}

h2 {
  font-size: 1.5rem;
  padding-bottom: 0.4rem;
}

.general-bg {
  border-radius: 10px;
  padding: 1rem 2rem;
}

mat-form-field {
  margin-right: 1rem;
}

.mat-mdc-text-field-wrapper {
  background-color: #F8F8F8 !important;
}

.btn-primary {
  background-color: #FDCB0B;
  color: #333333;
  border: none;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
  display: flex;
  --bs-btn-color: #333333;
  --bs-btn-bg: #FDCB0B;
  --bs-btn-border-color: #FDCB0B;
  --bs-btn-hover-color: #333333;
  --bs-btn-hover-bg: #E5B600;
  --bs-btn-hover-border-color: #E5B600;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #333333;
  --bs-btn-active-bg: #E5B600;
  --bs-btn-active-border-color: #E5B600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a1a1a1;
  --bs-btn-disabled-bg: #FCDF83;
  --bs-btn-disabled-border-color: #FCDF83;
}


.btn-secondary {
  background-color: #8E8E8E;
  color: #ffffff;
  border: none;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
  display: flex;
}

.btn-secondary:hover {
  background-color: #6D6D6D;
}

.btn-important {
  background-color: #CA0133;
  color: #ffffff;
  border: none;
  font-weight: 500;
  display: flex;
}

.btn-important:hover {
  background-color: #BC002F;
  color: #ffffff;
}

a {
  color: #CA0133;
  text-decoration: none;
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #333333;
  --mdc-chip-elevated-container-color: #FDCB0B;
  --mdc-chip-elevated-disabled-container-color: #FDCB0B;
  --mdc-chip-label-text-color: #333333;
  --mdc-chip-with-icon-icon-color: #333333;
  --mdc-chip-with-icon-disabled-icon-color: #333333;
  --mdc-chip-with-icon-selected-icon-color: #333333;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #333333;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #333333;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 2rem;
}

.card-header, .card-title {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.card-title-with-icon > mat-icon {
  padding-top: 6px;
}

.input-note {
  width: 400px;
}

.height-0 .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #CB0133;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #CB0133;
  --mat-tab-header-active-ripple-color: #CB0133;
  --mat-tab-header-inactive-ripple-color: #CB0133;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #CB0133;
  --mat-tab-header-active-hover-label-text-color: #CB0133;
  --mat-tab-header-active-focus-indicator-color: #CB0133;
  --mat-tab-header-active-hover-indicator-color: #CB0133;
  border-radius: 10px 10px 0 0;

}

.mdc-tab--active {
  background-color: #ffffff !important;
  border-radius: 10px 10px 0 0;
}

.mat-mdc-tab-body-wrapper {
  background-color: #ffffff;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  border-radius: 0 0 10px 10px;
}

.no-max-width {
  max-width: none !important;
}

.spinner-border {
  color: $primaryColor;
}

.time-off-event .cal-event {
  background-color: grey !important;
  color: white !important;
}

.prestation-event .cal-event {
  background-color: white !important;
}

.widget {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  max-width: 500px;

  h2 {
    display: flex;
    align-items: center;
  }

  .title-text {
    flex-grow: 1;
  }
}

.success-icon {
  color: #198754;
}

.warning-icon {
  color: #FFC107;
}

.repit-card {
  width: 19%;
}

.repit-card:hover {
  box-shadow: 3px 3px 8px #888888;
  cursor: pointer;
}

.repit-container-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: stretch;
}

.repit-container-2 > .box {
  flex: 49%; /*for some reason, using gap with 50% does not work*/
  width: 100%;
}

.small-input {
  width: 180px;
}

.small-input-2 {
  width: 150px;
}

.small-input-3 {
  width: 120px;
}

.selected-row {
  background-color: #FDCB0B !important;
  font-weight: bold !important;
}